import React, { memo, useEffect, useState } from 'react';

//Libraries
import SimpleBar from 'simplebar-react';

//Import methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';

export const TAB_TYPE = {
    CITY: 'City',
    COUNTRY: 'Country',
    NEARBY: 'Near'
};

const Seo = ({d}) => {

    //Check size
    const { isMobile, isTablet, isDesktop } = useDeviceDetect();

    //State
    const [info,setInfo] = useState(null);
    const [activeTab, setActiveTab] = useState(() => {
        return d.NearBy ? TAB_TYPE.NEARBY : TAB_TYPE.CITY
    });


    //Transform Function
    function transformData(data = {},lim){
        let obj = {cities:[],countries:[],};
        (data.Recommended || []).map((el,i) => i < lim && obj.cities.push(el));
        (data.TopCountries || []).map((el,i) => i < lim && obj.countries.push(el));
        if(data.NearBy){
            obj.nearby = [];
            (data.NearBy || []).map((el,i) => i < lim && obj.nearby.push(el));
        }
        return obj;
    }

    const dataDesktop = transformData(d,30);
    const dataTablet = transformData(d,30);
    const dataMobile = transformData(d,30);
    const dataInfo = isMobile ? dataMobile : isTablet ? dataTablet : dataDesktop;

    useEffect(() => {
        const viewportHandler = (predicate, { nearby, cities }) => {
            const data = [cities, nearby];
            setInfo(data[Number(predicate)]);
        };

        let data;

        switch (true) {
            case isMobile: {
                data = dataMobile;
                break;
            }
            case isTablet: {
                data = dataTablet;
                break;
            }
            default: {
                data = dataDesktop;
            }
        }

        viewportHandler(Boolean(d.NearBy), data);
    }, [isMobile, isTablet, isDesktop, d]);

    //Method
    const handleOptionClick = tabType => (e) => {
        setActiveTab(tabType);
        const element = e.target;
        const classList = element.classList;
        if(!element.classList.contains('cnhpseo--active')){
            //Add active class
            const activeElement = document.querySelector('.cnhpseo--active');
            activeElement.classList.remove('cnhpseo--active');
            element.classList.add('cnhpseo--active');
            //Change state
            switch(classList[1]) {
                case 'cnhpseo--1':
                  setInfo(dataInfo.cities);
                  break;
                case 'cnhpseo--2':
                  setInfo(dataInfo.countries);
                  break;
                case 'cnhpseo--3':
                  setInfo(dataInfo.nearby)
                break;
            }
        }
    }

    const renderLists = info && (
        info.map((el,i) => {
            return (
                <div
                    className="cn__hp__seo__listing__option"
                    key={i}
                    data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.seoLinks.tab.city[EAType.CLICK](activeTab, el.name) })}
                >
                    <a href={el.url?.startsWith('/') ?  el.url : `/${el.url || ''}`} target="_self">
                        <p className="cn__hp__seo__listing__option__title">{el.name}</p>
                    </a>
                </div>
            )
        })
    );

    const renderTabBar = (
        <>
            {d.NearBy && (
                <p
                    className="cn__hp__seo__options__option cnhpseo--3 cnhpseo--active"
                    onClick={handleOptionClick(TAB_TYPE.NEARBY)}
                    data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.seoLinks.tab[EAType.CLICK](TAB_TYPE.NEARBY) })}
                >
                    Places nearby
                </p>
            )}
            <p
                className={`cn__hp__seo__options__option cnhpseo--1 ${d.NearBy ? '':'cnhpseo--active'}`}
                onClick={handleOptionClick(TAB_TYPE.CITY)}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.seoLinks.tab[EAType.CLICK](TAB_TYPE.CITY) })}
            >
                Top destinations
            </p>
            <p
                className="cn__hp__seo__options__option cnhpseo--2"
                onClick={handleOptionClick(TAB_TYPE.COUNTRY)}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.seoLinks.tab[EAType.CLICK](TAB_TYPE.COUNTRY) })}
            >
                Top countries &#38; regions
            </p>
        </>
    );

    return(
        <section
            test-id="geopageHpSeo"
            className="cn__hp__seo"
            data-cn-ga-observe={JSON.stringify({ el: analyticsLabelProvider.seoLinks[EAType.SCROLL]() })}
        >
            <div test-id= "geopageSeoOption" className="cn__hp__seo__options">
                {isMobile ? (
                   <SimpleBar>
                        <div className="slidermobile__wrapper">
                            {renderTabBar}
                            <div style={{width: isMobile ? 10 : 20, flexShrink:0, marginLeft: 10}}/>
                        </div>
                    </SimpleBar>
                ): renderTabBar}
            </div>
            
            <div className="cn__hp__seo__listing">
                {isMobile ? (
                    <SimpleBar>
                        <div className="slidermobile__wrapper">
                            {renderLists}
                        </div>
                    </SimpleBar>
                ):(
                    <>
                        {renderLists}
                    </>
                )}
            </div>
        </section>
    )
}

export default memo(Seo);