import React, { memo } from 'react';

//Library
import clsx from 'clsx';

//Import methods
import { handleJSClick } from '@/components/Slider/utils';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import { getImageInfo } from '@/src/utils/globalFunc';
import { constants } from '@/src/utils/constants';

//Import styles
import styles from '@/src/styles/components/CareProgram/__cpp--CPBanner.module.scss';

//Import Components
import Image from '@/containers/newHomePage/components/Image';
import Button from '@/containers/newHomePage/components/Button';
import { KsaImage } from '@/components/Image';
import { KsaRegion, ksaRegions } from '@/static/ksa';
import useKsa from '@/utils/customHooks/useKsa';
import useResponsiveResource from '@/utils/customHooks/useResponsiveResource';


const CPBanner = () => {

    //Check
    const {isMobile, isDesktop, isTablet} = useDeviceDetect();

    const getKsaRegion = useKsa(ksaRegions);
    const ksaRegion = getKsaRegion();
    const ksaResponsiveBackground = useResponsiveResource(new Map([
        [`(max-width: 760px)`, `/static/home/ksa/${KsaRegion.MiddleEast}/care-mobile.jpg`],
        [`(min-width: 761px)`, `/static/home/ksa/${KsaRegion.MiddleEast}/care.jpg`]
    ]), [ksaRegion]);

    //Var
    const title = !isMobile ? (<>We’re waiving our 12% service fee for all qualifying property owners until 31 December 2024 with our <span>CuddlyCare Program</span>.</>) : (<>No 12% service fee until 31 December 2024 for qualifying property owners in the <span>CuddlyCare Program</span>.</>);

    const subtitle = 'Take advantage of this asset by applying now!';

    const sizes = {mobile:['527x297','1054x594'],tablet:['824x552','1648x1104'],desktop:['1270x867','2540x1734']};

    const img = getImageInfo('https://img.cuddlynest.com/images/static/home/cp-backgroundimage.jpg',sizes);
    const mobileImage = 'https://img.cuddlynest.com/assets/mobile/homepage/banners/cpbackground.jpg';
    const desktopImage = 'https://img.cuddlynest.com/assets/desktop/homepage/banners/cpbackground.jpg';
    const tabletImage = 'https://img.cuddlynest.com/assets/tablet/homepage/banners/cpbackground.jpg'
    //Methods
    const handleButtonClick = () => {
        handleJSClick(constants.care_program_route,'_blank');
    }

    return (
        <div className={styles.cn__careprogram__banner__wrapper}>
            <div className={clsx('cn__careprogram__banner',styles.cn__careprogram__banner)}>
                {/* <Image src={isDesktop ? desktopImage : isTablet ? tabletImage : mobileImage} /> */}
                <KsaImage
                    regionsMap={ksaRegions}
                    srcRegionMap={new Map([
                        [KsaRegion.MiddleEast, ksaResponsiveBackground]
                    ])}
                    component={Image}
                    src={isDesktop ? desktopImage : isTablet ? tabletImage : mobileImage} // --> This is not me
                    imgStyle={ksaRegions.has(ksaRegion)
                        ? ({ objectPosition: 'unset' })
                        : undefined}
                />
                <p className={styles.cn__careprogram__banner__infoleft}>
                    {title}
                </p>
                <div className={styles.cn__careprogram__banner__inforight}>
                    {!isMobile && (
                        <p className={styles.cn__careprogram__banner__inforight__info}>
                            {subtitle}
                        </p>
                    )}
                    <Button title="Claim benefit" click={handleButtonClick}/>
                </div>
            </div>
        </div>
    );
};

export default memo(CPBanner);