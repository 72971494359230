import { Button } from '@/components/formComponents/button';
import { FormHelpertext } from '@/components/formComponents/formHelperText';
import { Input } from '@/components/formComponents/input';
import { InputGroup } from '@/components/formComponents/inputGroup';
import { errorNotificationActions, successNotificationActions } from '@/redux/actions/globalActions';
import { subscribeApi } from '@/redux/services/global';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import { _setFocus, _validateEmail } from '@/src/utils/globalValidations';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';
import { inValidEmail } from '@/static/errorMessages';
import * as gtag from '@/utils/gtag';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../../components/Message';

// Libraries
// Import methods
// components

const Subscribe = (props) => {

    // Call action
    const dispatch = useDispatch();
    const notification = useSelector((state) => state.notifications);

    //Check size
    const { isMobile } = useDeviceDetect();

    // States
    const [ email, setEmail ] = useState();
    const [ emailErr, setEmailErr ] = useState(false);
    const [ name, setName] = useState();
    const [ loading, setLoading ] = useState(false);

    //Methods
    const router = useRouter();

    const _submitSubscribe = async e => {
        e.preventDefault();
        try{
            if (_validateEmail(email)) {
                setLoading(true);
                setEmailErr(false)
                const data = {
                    email: email,
                    source_page: router.pathname,
                    source_name: name
                };
                setEmail('');
                gtag.event({ action: EAType.CLICK, label: analyticsLabelProvider.subscribe[EAType.CLICK]() });
                const res = await subscribeApi(data);
                if (res.statusCode === 200) {
                    setLoading(false);
                    dispatch(successNotificationActions('Success', res.message, props.notificationTime));
                    setEmail('');
                } else{
                    setLoading(false);
                    dispatch(errorNotificationActions("Error", data.message, props.notificationTime));
                }
            } else {
                _setFocus('subsEmail');
                setEmailErr(true)
                return false;
            }
        } catch (err) {
            setEmail('');
            setLoading(false);
            dispatch(errorNotificationActions("Error", err.message, props.notificationTime));
        }
    }

    return (
        <div className="subscribe-section">
            <div className="subscribe-section-con">
                <div className="heading-section">
                    <div>
                        <h2>Stay inspired for upcoming trips</h2>
                        {!isMobile && <p>We’ll share travel tips, incentives and unmissable offers!</p>}
                    </div>
                    {
                        !notification.successMsgDescription ? (
                            <div className="subscribe-form">
                                <div className="subscribe-form_inputfields">
                                        <InputGroup>
                                            <Input
                                                type="name"
                                                className="primary-input input-empty"
                                                placeholder="Your name..."
                                                value={name}
                                                test-id="hp_subsName"
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Input
                                                type="email"
                                                className="primary-input input-empty"
                                                placeholder="Your email address..."
                                                value={email}
                                                id="subsEmail"
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                            {
                                                emailErr && (
                                                    <FormHelpertext className="err">
                                                        {inValidEmail}
                                                    </FormHelpertext>
                                                )
                                            }
                                        </InputGroup>
                                </div>
                                <Button
                                    test-id="hp_sub_btn"
                                    type="button"
                                    btnType="secondary"
                                    onClick={_submitSubscribe}
                                    disabled={loading}
                                >Subscribe</Button>
                            </div>
                        ) : (
                            <Message time={props.notificationTime - 800}/>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

Subscribe.defaultProps = {
    notificationTime: 5800
};

export default connect(null, { errorNotificationActions, successNotificationActions })(Subscribe);
