import { memo } from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useSettingsContext } from "@/containers/newHomePage/context";
import desktopImg from './assets/desktop.png';
import tabletImg from './assets/tablet.png';
import mobileImg from './assets/mobile.png';

const Crypto = ({ href }) => {
    const { styles } = useSettingsContext();

    return (
        <Box
            sx={styles.container}
            component='section'
        >
            <Box
                sx={{
                    ...styles.wrapper,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundPosition: 'bottom',
                    backgroundImage: `url(${mobileImg.src})`,
                    height: 300,

                    '@media (min-width: 600px)': {
                        backgroundImage: `url(${tabletImg.src})`,
                        backgroundPosition: 'center',
                        height: tabletImg.height,
                        maxHeight: 400,
                    },

                    '@media (min-width: 1130px)': {
                        backgroundImage: `url(${desktopImg.src})`,
                        height: desktopImg.height,
                        maxHeight: 'unset'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        height: '100%',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            px: 5,
                            '@media (min-width: 600px)': {
                                px: 0,
                                ml: '7%',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'var(--color-black)',
                                fontFamily: 'var(--primary-font)',
                                lineHeight: 1.2,
                                fontWeight: 700,
                                fontSize: 24,

                                '@media (min-width: 600px)': {
                                    fontSize: 28,
                                },

                                '@media (min-width: 800px)': {
                                    fontSize: 36,
                                },

                                '@media (min-width: 1130px)': {
                                    fontSize: 44
                                }
                            }}
                        >
                            Book and pay with <br />
                            Crypto Currency
                        </Typography>
                        <Typography
                            sx={{
                                color: 'var(--color-black)',
                                fontFamily: 'var(--primary-font)',
                                fontWeight: 400,
                                fontSize: 16,
                                mt: 1,
                                mb: 6,

                                '@media (min-width: 600px)': {
                                    my: 3,
                                    fontSize: 18,
                                },

                                '@media (min-width: 1130px)': {
                                    my: 4,
                                    fontSize: 20
                                }
                            }}
                        >
                            Simplified payments
                        </Typography>
                        <ButtonBase
                            href={href}
                            sx={{
                                backgroundImage: 'linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%)',
                                borderRadius: '12px !important',
                                color: 'var(--color-white) !important',
                                fontFamily: 'var(--primary-font)',
                                fontWeight: 600,
                                fontSize: 14,
                                maxWidth: 195,
                                width: '100%',
                                py: '10px !important',
                                px: '16px !important',
                                boxShadow: '0 4px 4px rgba(0, 0, 0, .25)',
                            }}
                        >
                            Cryptocurrency
                        </ButtonBase>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(Crypto);
